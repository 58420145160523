import cns from 'classnames'
import PropTypes from 'prop-types'

import styles from './wrapper.module.scss'

function Wrapper({ children, className, extended }) {
  return (
    <div className={cns(styles.root, className, extended && styles.root_extended)}>
      {children}
    </div>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  extended: PropTypes.bool,
}

export default Wrapper

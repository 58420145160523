import { discount } from '@components/pricingSwitchers/period/periodData'

const calculateDiscountPrice = (price, discount) => {
  return price - price * (discount / 100)
}

export const pricingPlansData = [
  {
    id: 'blogger',
    counter: true,
  },
  {
    id: 'business',
    withIcon: true,
    counter: true,
  },
  {
    id: 'agency',
    benefits: ['accounts', 'employees', 'retrospective'],
    counter: true,
  },
  {
    id: 'custom',
    counter: false,
  },
]

export const pricingPlansBenefits = {
  blogger: {
    accounts: 'pricing.plans.benefits.blogger.accounts',
    employees: 'pricing.plans.benefits.blogger.employees',
    retentionPeriod: 'pricing.plans.benefits.blogger.retentionPeriod',
  },
  business: {
    accounts: 'pricing.plans.benefits.business.accounts',
    employees: 'pricing.plans.benefits.business.employees',
    retentionPeriod: 'pricing.plans.benefits.business.retentionPeriod',
  },
  agency: {
    accounts: 'pricing.plans.benefits.agency.accounts',
    employees: 'pricing.plans.benefits.agency.employees',
    retentionPeriod: 'pricing.plans.benefits.agency.retentionPeriod',
  },
  custom: {
    accounts: 'pricing.plans.benefits.custom.accounts',
    employees: 'pricing.plans.benefits.custom.employees',
    retentionPeriod: 'pricing.plans.benefits.custom.retentionPeriod',
  },
}

export const pricesPlansPrices = {
  blogger: {
    ru: {
      month: {
        price: 1100,
        defaultPrice: 1100,
        account: '-',
        employee: '-',
      },
      year: {
        price: calculateDiscountPrice(1100, discount),
        defaultPrice: calculateDiscountPrice(1100, discount),
        account: '-',
        employee: '-',
      },
    },
    kz: {
      month: {
        price: 8000,
        defaultPrice: 8000,
        account: '-',
        employee: '-',
      },
      year: {
        price: calculateDiscountPrice(8000, discount),
        defaultPrice: calculateDiscountPrice(8000, discount),
        account: '-',
        employee: '-',
      },
    },
    en: {
      month: {
        price: 17,
        defaultPrice: 17,
        account: '-',
        employee: '-',
      },
      year: {
        price: calculateDiscountPrice(17, discount),
        defaultPrice: calculateDiscountPrice(17, discount),
        account: '-',
        employee: '-',
      },
    },
    ua: {
      month: {
        price: 17,
        defaultPrice: 17,
        account: '-',
        employee: '-',
      },
      year: {
        price: calculateDiscountPrice(17, discount),
        defaultPrice: calculateDiscountPrice(17, discount),
        account: '-',
        employee: '-',
      },
    },
    br: {
      month: {
        price: 75,
        defaultPrice: 75,
        account: '-',
        employee: '-',
      },
      year: {
        price: calculateDiscountPrice(75, discount),
        defaultPrice: calculateDiscountPrice(75, discount),
        account: '-',
        employee: '-',
      },
    },
  },
  business: {
    ru: {
      month: {
        price: 3000,
        defaultPrice: 3000,
        account: 200,
        employee: 300,
      },
      year: {
        price: calculateDiscountPrice(3000, discount),
        defaultPrice: calculateDiscountPrice(3000, discount),
        account: calculateDiscountPrice(200, discount),
        employee: calculateDiscountPrice(300, discount),
      },
    },
    kz: {
      month: {
        price: 22500,
        defaultPrice: 22500,
        account: 1500,
        employee: 2200,
      },
      year: {
        price: calculateDiscountPrice(22500, discount),
        defaultPrice: calculateDiscountPrice(22500, discount),
        account: calculateDiscountPrice(1500, discount),
        employee: calculateDiscountPrice(2200, discount),
      },
    },
    en: {
      month: {
        price: 44,
        defaultPrice: 44,
        account: 3,
        employee: 5,
      },
      year: {
        price: calculateDiscountPrice(44, discount),
        defaultPrice: calculateDiscountPrice(44, discount),
        account: calculateDiscountPrice(3, discount),
        employee: calculateDiscountPrice(5, discount),
      },
    },
    ua: {
      month: {
        price: 44,
        defaultPrice: 44,
        account: 3,
        employee: 5,
      },
      year: {
        price: calculateDiscountPrice(44, discount),
        defaultPrice: calculateDiscountPrice(44, discount),
        account: calculateDiscountPrice(3, discount),
        employee: calculateDiscountPrice(5, discount),
      },
    },
    br: {
      month: {
        price: 225,
        defaultPrice: 225,
        account: 15,
        employee: 25,
      },
      year: {
        price: calculateDiscountPrice(225, discount),
        defaultPrice: calculateDiscountPrice(225, discount),
        account: calculateDiscountPrice(15, discount),
        employee: calculateDiscountPrice(25, discount),
      },
    },
  },
  agency: {
    ru: {
      month: {
        prices: {
          100: 9900,
          200: 12400,
          300: 14900,
        },
        defaultPrice: {
          100: 9900,
          200: 12400,
          300: 14900,
        },
        account: 200,
        employee: 300,
      },
      year: {
        prices: {
          100: 8910,
          200: 11160,
          300: 13410,
        },
        defaultPrice: {
          100: 8910,
          200: 11160,
          300: 13410,
        },
        account: calculateDiscountPrice(200, discount),
        employee: calculateDiscountPrice(300, discount),
      },
    },
    kz: {
      month: {
        prices: {
          100: 75000,
          200: 95000,
          300: 112000,
        },
        defaultPrice: {
          100: 75000,
          200: 95000,
          300: 112000,
        },
        account: 1500,
        employee: 2200,
      },
      year: {
        prices: {
          100: 67500,
          200: 85500,
          300: 100800,
        },
        defaultPrice: {
          100: 67500,
          200: 85500,
          300: 100800,
        },
        account: calculateDiscountPrice(1500, discount),
        employee: calculateDiscountPrice(2200, discount),
      },
    },
    en: {
      month: {
        prices: {
          100: 150,
          200: 185,
          300: 225,
        },
        defaultPrice: {
          100: 150,
          200: 185,
          300: 225,
        },
        account: 3,
        employee: 5,
      },
      year: {
        prices: {
          100: 135,
          200: 166.5,
          300: 202.5,
        },
        defaultPrice: {
          100: 135,
          200: 166.5,
          300: 202.5,
        },
        account: calculateDiscountPrice(3, discount),
        employee: calculateDiscountPrice(5, discount),
      },
    },
    ua: {
      month: {
        prices: {
          100: 150,
          200: 185,
          300: 225,
        },
        defaultPrice: {
          100: 150,
          200: 185,
          300: 225,
        },
        account: 3,
        employee: 5,
      },
      year: {
        prices: {
          100: 135,
          200: 166.5,
          300: 202.5,
        },
        defaultPrice: {
          100: 135,
          200: 166.5,
          300: 202.5,
        },
        account: calculateDiscountPrice(3, discount),
        employee: calculateDiscountPrice(5, discount),
      },
    },
    br: {
      month: {
        prices: {
          100: 1000,
          200: 1500,
          300: 2000,
        },
        defaultPrice: {
          100: 1000,
          200: 1500,
          300: 2000,
        },
        account: 15,
        employee: 25,
      },
      year: {
        prices: {
          100: calculateDiscountPrice(1000, discount),
          200: calculateDiscountPrice(1500, discount),
          300: calculateDiscountPrice(2000, discount),
        },
        defaultPrice: {
          100: calculateDiscountPrice(1000, discount),
          200: calculateDiscountPrice(1500, discount),
          300: calculateDiscountPrice(2000, discount),
        },
        account: calculateDiscountPrice(15, discount),
        employee: calculateDiscountPrice(25, discount),
      },
    },
  },
}

export const pricesPlansSigns = {
  ru: '₽',
  kz: '₸',
  en: '$',
  br: 'R$',
  ua: '$',
}

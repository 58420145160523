import { useEffect, useRef, useState } from 'react'
import cns from 'classnames'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import { currencies } from '@components/pricingSwitchers/currency/currencyData'

import useFormatMessage from '@utils/useFormatMessage'
import $amplitude from '@utils/amplitude'

import IconDropdownArrow from '@icons/currencyDropdownArrow.svg'
import IconFlagRu from '@icons/flags/ru.svg'
import IconFlagKz from '@icons/flags/kz.svg'
import IconFlagEn from '@icons/flags/en.svg'
import IconFlagUa from '@icons/flags/ua.svg'
import IconFlagBr from '@icons/flags/br.svg'

import styles from './currency.module.scss'

const flagsMap = {
  ru: IconFlagRu,
  kz: IconFlagKz,
  en: IconFlagEn,
  ua: IconFlagUa,
  br: IconFlagBr,
}

const getFlagIcon = currency => {
  const Icon = flagsMap[currency]
  return <Icon />
}

function Currency({ selectedCurrency, setSelectedCurrency }) {
  const buttonRef = useRef(null)
  const t = useFormatMessage()
  const { locale } = useRouter()

  const [isDropdownVisible, setIsDropdownVisible] = useState(false)

  const handleClick = currency => () => {
    setSelectedCurrency(currency)
    $amplitude('[Landing] Tariff Currency Select Click', {
      сurrency_type:
        currency === 'ru'
          ? 'rub'
          : currency === 'en'
          ? 'usd'
          : currency === 'br'
          ? 'brl'
          : 'kzt',
    })
  }

  useEffect(() => {
    const handleClick = event => {
      if (!buttonRef.current?.contains(event.target)) {
        setIsDropdownVisible(false)
      }
    }

    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  useEffect(() => {
    setSelectedCurrency(currencies[locale] || currencies[2])
  }, [locale, setSelectedCurrency])

  return (
    <div className={styles.root}>
      <button
        ref={buttonRef}
        className={styles.button}
        onClick={() => setIsDropdownVisible(!isDropdownVisible)}
      >
        <span className={styles.currency}>
          <span className={styles.currency__icon}>
            {getFlagIcon(selectedCurrency)}
          </span>
          {t(`currencies.${selectedCurrency}`)}
        </span>

        <IconDropdownArrow className={styles.dropDownArrow} />
      </button>

      <div className={styles.listWrapper}>
        <ul
          className={cns(styles.list, !isDropdownVisible && `visually-hidden`)}
        >
          {currencies.map((currency, i) => (
            <li key={i}>
              <button
                className={styles.itemButton}
                onClick={handleClick(currency)}
              >
                <span className={styles.currency}>
                  {getFlagIcon(currency)}

                  {t(`currencies.${currency}`)}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

Currency.propTypes = {
  selectedCurrency: PropTypes.string,
  setSelectedCurrency: PropTypes.func,
}

export default Currency

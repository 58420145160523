import { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import cns from 'classnames'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { LINK_DEMO_FORM } from '@constants/index'

import $amplitude from '@utils/amplitude'
import useLanguageUrl from '@utils/useLanguageUrl'
import useFormatMessage from '@utils/useFormatMessage'
import { useABTest } from '@utils/useABTest'

import {
  pricesPlansPrices,
  pricesPlansSigns,
  pricingPlansBenefits,
  pricingPlansData,
} from '@data/pricingPlansData'

import IconStar from '@icons/star.svg'
import IconRoundCheck from '@icons/round-check.svg'
import Minus from '@icons/minus.svg'
import Plus from '@icons/plus.svg'

import Wrapper from '@components/layouts/wrapper/wrapper'
import SuccessModal from '@components/demoModal/SuccesModal/SuccessModal'
import DemoModal from '@components/demoModal/DemoModal/DemoModal'

import styles from './pricingPlans.module.scss'

const minValues = {
  blogger: 5,
  business: 10,
  agency: 100,
}
const maxValues = {
  blogger: 5,
  business: 25,
  agency: 300,
}
const step = {
  blogger: 1,
  business: 5,
  agency: 100,
}

export default function PricingPlans({
  isFree = false,
  isWithoutGradient,
  pageId,
  selectedCurrency,
  selectedPeriod,
}) {
  const t = useFormatMessage()
  const { locale } = useRouter()
  const langUrl = useLanguageUrl()
  const { isABTestForPathname2 } = useABTest()
  const isBrazil = locale === 'br'

  const [countersValue, setCountersValue] = useState(minValues)
  const [activeCounter, setActiveCounter] = useState(null)
  const [pricesData, setPricesData] = useState(pricesPlansPrices)
  const [isDemoModalOpen, setIsDemoModalOpen] = useState(false)
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false)

  const handleRegistrationButtonClick = plan => {
    $amplitude('[Landing] Registration Button Click', {
      page_name: pageId,
      block_name: 'other',
    })

    $amplitude('[Landing] Selected Try For Free', {
      product_type: plan.id,
    })
  }

  const handleLearnMoreButtonClick = planId => {
    $amplitude('[Landing] Selected Learn More', {
      product_type: planId,
    })
  }

  const handleContactUsButtonClick = () => {
    setIsDemoModalOpen(true)
    $amplitude('[Landing] Selected Contact Us')
    $amplitude('[Landing] Demo Modal Window View')
  }

  const handleCloseDemoModal = () => {
    setIsDemoModalOpen(false)
  }

  const handleOpenSuccessModal = () => {
    setIsDemoModalOpen(false)
    setIsSuccessModalOpen(true)
  }

  const handleCloseSuccessModal = () => setIsSuccessModalOpen(false)

  const increasePrice = useCallback(
    (planType, increaseValue) => {
      setPricesData(prices => {
        const currentPrice =
          prices[planType][selectedCurrency][selectedPeriod].price
        const accountPrice =
          prices[planType][selectedCurrency][selectedPeriod].account
        let newPrice = currentPrice + accountPrice * increaseValue

        return {
          ...prices,
          [planType]: {
            ...prices[planType],
            [selectedCurrency]: {
              ...prices[planType][selectedCurrency],
              [selectedPeriod]: {
                ...prices[planType][selectedCurrency][selectedPeriod],
                price: newPrice,
              },
            },
          },
        }
      })
    },
    [selectedCurrency, selectedPeriod]
  )

  const decreasePrice = useCallback(
    (planType, decreaseValue) => {
      setPricesData(prices => {
        const currentPrice =
          prices[planType][selectedCurrency][selectedPeriod].price
        const account =
          prices[planType][selectedCurrency][selectedPeriod].account
        let newPrice = currentPrice + account * decreaseValue

        return {
          ...prices,
          [planType]: {
            ...prices[planType],
            [selectedCurrency]: {
              ...prices[planType][selectedCurrency],
              [selectedPeriod]: {
                ...prices[planType][selectedCurrency][selectedPeriod],
                price: newPrice,
              },
            },
          },
        }
      })
    },
    [selectedCurrency, selectedPeriod]
  )

  const resetPrice = useCallback(
    planType => {
      setPricesData(prices => {
        return {
          ...prices,
          [planType]: {
            ...prices[planType],
            [selectedCurrency]: {
              ...prices[planType][selectedCurrency],
              [selectedPeriod]: {
                ...prices[planType][selectedCurrency][selectedPeriod],
                price:
                  prices[planType][selectedCurrency][selectedPeriod]
                    .defaultPrice,
              },
            },
          },
        }
      })
    },
    [selectedCurrency, selectedPeriod]
  )

  const updatePrice = (planType, counterValue) => {
    if (planType === 'agency') {
      setPricesData(prices => ({
        ...prices,
        [planType]: {
          ...prices[planType],
          [selectedCurrency]: {
            ...prices[planType][selectedCurrency],
            [selectedPeriod]: {
              ...prices[planType][selectedCurrency][selectedPeriod],
              price:
                prices[planType][selectedCurrency][selectedPeriod].defaultPrice[
                  counterValue
                ],
            },
          },
        },
      }))
    }
  }

  const updateCounter = (planType, value) => {
    if (
      countersValue[planType] + value >= minValues[planType] &&
      countersValue[planType] + value <= maxValues[planType]
    ) {
      let newCounterValue = countersValue[planType] + value

      setCountersValue({
        ...countersValue,
        [planType]: newCounterValue,
      })
      if (planType !== 'agency') {
        value > 0
          ? increasePrice(planType, value)
          : decreasePrice(planType, value)
      } else {
        updatePrice(planType, newCounterValue)
      }

      setActiveCounter(planType)
    }
  }

  const increaseCounter = planType => {
    updateCounter(planType, step[planType])
  }

  const decreaseCounter = planType => {
    updateCounter(planType, -step[planType])
  }

  const buttonText = isFree
    ? 'pricing.plans.buttonTextFree'
    : 'pricing.plans.buttonText'

  const pricingHreffBR = isFree ? '/precos#details' : `${locale}/precos#details`
  const pricingHrefOthers = isFree
    ? '/pricing#details'
    : `${locale}/pricing#details`
  const pricingHref = locale === 'br' ? pricingHreffBR : pricingHrefOthers

  useEffect(() => {
    setCountersValue({ ...minValues })
    resetPrice('blogger')
    resetPrice('business')
    resetPrice('agency')
  }, [selectedCurrency, selectedPeriod, resetPrice])

  return (
    <div
      className={cns(styles.root, isWithoutGradient && styles.withoutGradient)}
    >
      <Wrapper className={styles.listWrapper} extended>
        <ul className={styles.list}>
          {pricingPlansData.map((plan, i) => {
            let price
            if (plan.id !== 'agency') {
              price = +(
                pricesData?.[plan.id]?.[selectedCurrency]?.[selectedPeriod]
                  ?.price || 0
              ).toFixed(1)
            } else {
              price =
                pricesData[plan.id][selectedCurrency][selectedPeriod].prices[
                  countersValue[plan.id]
                ] || 0
            }

            if (plan.id === 'custom') {
              price = null
            }

            const sign = pricesPlansSigns[selectedCurrency]

            return (
              <li className={styles.item} key={i}>
                {plan.withIcon && <IconStar className={styles.item__ico} />}
                <p className={styles.item__title}>
                  {t(`pricing.plans.titles.${plan.id}`)}
                </p>
                <p className={cns(styles.item__text)}>
                  {t(`pricing.plans.texts.${plan.id}`)}
                </p>
                {plan.id === 'custom' ? (
                  <button
                    onClick={handleContactUsButtonClick}
                    className={cns(
                      styles.item__button,
                      plan.id !== 'business' && styles.item__button_ghost,
                      `button button--secondary button--small button--full`
                    )}
                  >
                    {t(`pricing.plans.buttonText.custom`)}
                  </button>
                ) : (
                  <a
                    className={cns(
                      styles.item__button,
                      plan.id !== 'business' && styles.item__button_ghost,
                      `button button--secondary button--small button--full`
                    )}
                    href={
                      plan.id === 'custom' ? LINK_DEMO_FORM[locale] : langUrl
                    }
                    onClick={() => handleRegistrationButtonClick(plan)}
                    rel="nofollow"
                  >
                    {t(isABTestForPathname2 ? 'try' : buttonText)}
                  </a>
                )}
                <p className={styles.item__price}>
                  <span className={styles.item__value}>
                    {/*в бразильском языке значек валюты ставим перед ценой*/}
                    {price && isBrazil && <span>{` ${sign}`}</span>}

                    {typeof price === 'number'
                      ? price
                      : t(`pricing.plans.prices.custom`)}
                    {price && !isBrazil && (
                      <span className={styles.item__symbol}>{` ${sign} `}</span>
                    )}
                  </span>

                  {price && (
                    <span className={styles.item__label}>
                      {isBrazil && ` `}/ {t(`pricing.plans.period`)}
                    </span>
                  )}
                </p>
                <ul className={styles.item__benefits}>
                  {Object.entries(pricingPlansBenefits[plan.id]).map(
                    ([key, value]) => (
                      <li className={styles.benefits__item} key={key}>
                        <IconRoundCheck className={styles.benefits__ico} />
                        {t(value)}
                        {plan.counter && key === 'accounts' && (
                          <div className={styles.counter}>
                            <button
                              className={styles.counter__button}
                              onClick={() => decreaseCounter(plan.id)}
                            >
                              <Minus
                                className={cns(styles.counter__icon, {
                                  [styles.counter__icon_disabled]:
                                    countersValue[plan.id] ===
                                    minValues[plan.id],
                                })}
                              />
                            </button>
                            <span
                              className={cns(styles.counter__input, {
                                [styles.counter__input_active]:
                                  activeCounter === plan.id,
                              })}
                            >
                              {countersValue[plan.id]}
                            </span>

                            <button
                              className={styles.counter__button}
                              onClick={() => increaseCounter(plan.id)}
                            >
                              <Plus
                                className={cns(styles.counter__icon, {
                                  [styles.counter__icon_disabled]:
                                    countersValue[plan.id] ===
                                    maxValues[plan.id],
                                })}
                              />
                            </button>
                          </div>
                        )}
                      </li>
                    )
                  )}
                </ul>
                {plan.id === 'custom' ? null : pageId !== 'pricing' ? (
                  <Link
                    className={cns(styles.item__link, `main-link`)}
                    href={pricingHref}
                    onClick={() => handleLearnMoreButtonClick(plan.id)}
                  >
                    {t(`pricing.plans.linkText`)}
                  </Link>
                ) : (
                  <a
                    className={cns(styles.item__link, `main-link`)}
                    href={'#details'}
                  >
                    {t(`pricing.plans.linkText`)}
                  </a>
                )}
              </li>
            )
          })}
        </ul>
      </Wrapper>
      {isDemoModalOpen && (
        <DemoModal
          isOpen={isDemoModalOpen}
          handleOpenSuccessModal={handleOpenSuccessModal}
          onClose={handleCloseDemoModal}
          locale={locale}
        />
      )}
      {isSuccessModalOpen && (
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={handleCloseSuccessModal}
        />
      )}
    </div>
  )
}

PricingPlans.propTypes = {
  isWithoutGradient: PropTypes.bool,
  pageId: PropTypes.string,
  selectedCurrency: PropTypes.string,
  selectedPeriod: PropTypes.string,
}

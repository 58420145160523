import PropTypes from 'prop-types'
import cn from 'classnames'

import Wrapper from '@components/layouts/wrapper/wrapper'
import Breadcrumbs from '@components/layouts/breadcrumbs/breadcrumbs'

import styles from './heroNew.module.scss'

function HeroNew({
  isH1 = false,
  children,
  title,
  text,
  pageId,
  breadcrumbsList = null,
}) {
  const isHomePage = pageId === 'home'
  const isPricing = pageId === 'pricing'

  const shouldExtendWrapper = !isHomePage

  const getTitleTag = () =>
    isH1 ? (
      <h1 className={styles.title}>{title}</h1>
    ) : (
      <h2 className={styles.title}>{title}</h2>
    )

  const root = cn(styles.root, {
    [styles.root__pricing]: isPricing,
    [styles.root__home]: isHomePage,
  })

  return (
    <div className={root}>
      <Wrapper extended={shouldExtendWrapper}>
        {breadcrumbsList && <Breadcrumbs list={breadcrumbsList} />}

        {title && getTitleTag()}

        {text && <p className={styles.text}>{text}</p>}

        {children}
      </Wrapper>
    </div>
  )
}

HeroNew.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  title: PropTypes.string,
  pageId: PropTypes.string,
  breadcrumbsList: PropTypes.array,
}

export default HeroNew

import { currencies } from '@components/pricingSwitchers/currency/currencyData'

const convertEmailLetter = l => {
  const upper = l.toLocaleLowerCase() !== l
  let r = ''
  switch (l.toLocaleLowerCase()) {
    case 'а':
      r = 'f'
      break
    case 'б':
      r = upper ? '<' : ','
      break
    case 'в':
      r = 'd'
      break
    case 'г':
      r = 'u'
      break
    case 'д':
      r = 'l'
      break
    case 'е':
      r = 't'
      break
    case 'ё':
      r = upper ? '~' : '`'
      break
    case 'ж':
      r = upper ? ':' : ';'
      break
    case 'з':
      r = 'p'
      break
    case 'и':
      r = 'b'
      break
    case 'й':
      r = 'q'
      break
    case 'к':
      r = 'r'
      break
    case 'л':
      r = 'k'
      break
    case 'м':
      r = 'v'
      break
    case 'н':
      r = 'y'
      break
    case 'о':
      r = 'j'
      break
    case 'п':
      r = 'g'
      break
    case 'р':
      r = 'h'
      break
    case 'с':
      r = 'c'
      break
    case 'т':
      r = 'n'
      break
    case 'у':
      r = 'e'
      break
    case 'ф':
      r = 'a'
      break
    case 'х':
      r = upper ? '{' : '['
      break
    case 'ц':
      r = 'w'
      break
    case 'ч':
      r = 'x'
      break
    case 'ш':
      r = 'i'
      break
    case 'щ':
      r = 'o'
      break
    case 'ъ':
      r = upper ? '}' : ']'
      break
    case 'ы':
      r = 's'
      break
    case 'ь':
      r = 'm'
      break
    case 'э':
      r = upper ? '"' : "'"
      break
    case 'ю':
      r = upper ? '>' : '.'
      break
    case 'я':
      r = 'z'
      break
    case '"':
      r = '@'
      break
    default:
      r = l
  }
  return upper ? r.toUpperCase() : r
}

export const convertEmail = email => {
  let result = ''
  for (let i = 0; i < email.length; i++) {
    result += convertEmailLetter(email[i])
  }
  return result
}

export const isDotCom = () => window?.isDotCom === true

export const getDefaultCurrency = locale =>
  currencies.find(currency => currency === locale) || currencies[2]

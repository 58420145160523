import PropTypes from 'prop-types'

import Currency from '@components/pricingSwitchers/currency/currency'
import Period from '@components/pricingSwitchers/period/period'

import styles from './pricingSwitchers.module.scss'

function PricingSwitchers({
  selectedPeriod,
  selectedCurrency,
  setSelectedPeriod,
  setSelectedCurrency,
}) {
  return (
    <div className={styles.root}>
      <Period
        selectedPeriod={selectedPeriod}
        setSelectedPeriod={setSelectedPeriod}
      />

      <Currency
        selectedCurrency={selectedCurrency}
        setSelectedCurrency={setSelectedCurrency}
      />
    </div>
  )
}

PricingSwitchers.propTypes = {
  selectedCurrency: PropTypes.string,
  selectedPeriod: PropTypes.string,
  setSelectedCurrency: PropTypes.func,
  setSelectedPeriod: PropTypes.func,
}

export default PricingSwitchers
